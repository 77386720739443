import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';
import logo from "./gato.webp";
import gif from "./gif.gif";
import pass from "./pass.gif";
import eagle from "./eagle.gif";
import love from "./love.gif";
import soldier from "./soldier.gif";

function App() {
  const [data, setData] = useState({
    tokenName: "",
    twitterLink: "",
    telegramLink: "",
    tokenCA: "",
    link: ""
  });

  // Função para buscar os dados da API
  useEffect(() => {
    axios.get('https://apitoreturnca.onrender.com/api/purchaseData')
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error("Erro ao buscar dados da API:", error);
      });
  }, []);

  return (
    <div className="App">
      <img src={gif} alt="" className="gif" />
      <img src={pass} alt="" className="a pass" />
      <img src={eagle} alt="" className="a eagle" />
      <img src={love} alt="" className="a love" />
      <img src={soldier} alt="" className="a soldier" />

      <div className="tokenName">{data.tokenName}</div>

      <div className="buttons">
        <a href={data.twitterLink} target="_blank" rel="noopener noreferrer" className='link x'>X/Twitter</a>
        <a href={data.telegramLink} target="_blank" rel="noopener noreferrer" className='link t'>Telegram</a>
      </div>

      <div className="tokenCa">
        CA: {data.tokenCA}
      </div>

      <div className="img">
        <a href={data.link} target="_blank" rel="noopener noreferrer">
          <img src={logo} alt="" />
        </a>
      </div>
    </div>
  );
}

export default App;
